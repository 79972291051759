/* Adjust DejaVu Sans font positioning */
.the-DrawerMenu-Header > span {
  position: relative;
  top: 4px;
}
.the-DrawerMenu-Body span.MuiFormControlLabel-label {
  position: relative;
  top: 2px;
}
.the-DrawerMenu-Body button .MuiButton-label {
  position: relative;
  top: 1px;
}

.the-DrawerMenu-Paper {
  width: var(--drawer-width);
  background-color: #2d2d2d !important; /* Darker gray than default */
}

.the-DrawerMenu-Header {
  height: var(--appbar-height);
  font-size: 16px;
}

.the-DrawerMenu-Header button {
  margin: 1px;
}

.the-DrawerMenu-Body {
  margin: 15px;

  /* To help put things on the bottom */
  height: 100%;
  display: flex;
  flex-direction: column;
}

.the-DrawerMenu-Body .MuiSwitch-root {
  margin-left: 5px;
}

.the-DrawerMenu-Body button {
  font-family: 'DejaVu Sans', sans-serif;
  text-transform: none;
  margin-top: 10px;
}

.the-DrawerMenu-Body hr {
  margin: 10px 0;
}

/* Push the last divider to the bottom */
.the-DrawerMenu-Body hr:last-of-type {
  margin-top: auto;
}
