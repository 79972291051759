.the-HomeScreen-MainImage {
  max-width: 100%;
  max-height: 100%;
  margin: auto; /* Center horizontally */
  display: block; /* Remove mysterious 5px bottom margin on Firefox */
  box-shadow: var(--paper-shadow);

  height: calc(100vh - var(--appbar-height));;
  object-fit: contain;
}

@media screen and (max-width: 340px) {
  .the-HomeScreen-Container {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .the-HomeScreen-MainImage {
    width: 100%;
    position: fixed;
  }
}