.the-DrawerMenuInputGroup-FormControl {
  margin-bottom: 10px !important;
}

.the-DrawerMenuInputGroup-RadioGroup, .the-DrawerMenuInputGroup-CheckboxGroup {
  margin: 5px 12px;
}

.the-DrawerMenuInputGroup-RadioButton, .the-DrawerMenuInputGroup-Checkbox {
  padding: 7px 9px !important; /* Reduce top-bottom from 9px */
}