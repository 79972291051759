@keyframes move-clouds {
  0% {
    transform: translate(-50%, 0);
  }
  50% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-50%, 0);
  }
}

@keyframes pulsate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

html {
  overflow-y: scroll; /* Force vertical scrollbar for a consistent page size */
}

body {
  /* Define variables (okay, "custom properties") */
  --drawer-width: 260px;
  --appbar-height: 50px; /* Make sure this matches APPBAR_HEIGHT in constants.js */
  --paper-shadow: /* From Material-UI Paper component */
    0px 2px 6px rgba(0, 0, 0, 0.4),
    0px 4px 5px rgba(0, 0, 0, 0.24),
    0px 1px 10px rgba(0, 0, 0, 0.12);

  font-family: 'DejaVu Sans', sans-serif !important;
  color: #cfd8dc !important; /* Material-UI blueGrey 100 */
  overflow: hidden;
}

.the-App-BackgroundContainer {
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: -1;
}

.the-App-Background {
  /* Clouds background adapted from https://codepen.io/agoodwin/pen/NMJoER
   and https://medium.com/iporaitech/css-background-animation-avoiding-high-cpu-usage-58947ff50900 */
  position: fixed;
  z-index: -1;
  width: 200vw;
  height: 195vh; /* Only needs to be 100% but I like this upper part of the image more */
  background-color: black !important;
  background-image: url("../clouds_repeat.png"), url("../stars.png");
  background-size: contain;
  background-position: 32%;
  /* Disabled animation because it still uses too much CPU on Linux; if re-enabling, remove stars (from this layer). */
  /* animation: move-clouds 300s linear infinite; */
}

.the-App-Root {
  min-width: 310px;
}

.the-App-AppBar, .the-App-AppBar-Toolbar {
  min-height: var(--appbar-height) !important;
  height: var(--appbar-height);
}

.the-App-AppBar-Tabs {
  flex-grow: 1;
}

.the-App-AppBar-Tabs a {
  font-family: 'Cinzel Decorative', sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-transform: none;
}

.the-App-AppBar-MenuButton {
  margin-right: 0px !important; /* Increased from -12px */
}

.the-App-AppBar-MenuButton.the-tutorial {
  animation: pulsate 2s linear infinite;
  border: 5px solid #ffab00 !important; /* Orangeish yellow */
  z-index: 100;
}

/* react-image-lightbox */

.ril-toolbar {
  left: auto !important; /* Default is 0 */
}

.ril-caption {
  right: auto !important; /* Default is 0 */
  max-width: 100%;
  background: rgba(0, 0, 0, 0.8) !important; /* Default is rgba(0, 0, 0, 0.5) */
}

.ril-toolbar-left {
  padding-left: 5px !important;
}

.ril-toolbar-right {
  padding-right: 5px !important;
}

.ril__errorContainer {
  font-size: 16px;
  margin: 70px;
}

.ril-caption-content {
  font-size: 16px;
  padding: 10px !important;
}
