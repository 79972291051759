@keyframes twinkle {
  0% {
    opacity: 0, 0;
  }
  50% {
    opacity: 100, 0;
  }
  100% {
    opacity: 0, 0;
  }
}

.the-MomentList-Container {
  text-align: center;
  margin-top: 15px;
}

.the-MomentList-Card {
  display: inline-block;
  margin: 2.5px 5px;
  width: 300px; /* Make sure this matches MOMENT_CARD_WIDTH in constants.js */
  border: 1px solid rgba(255, 255, 255, 0.4) !important; /* Default is 0.12 alpha */
}

.the-MomentList-Card {
  background-color: rgba(0, 0, 0, 0.3) !important;
}
.the-MomentList-Card a {
  font-size: 16px;
  color: white;
  text-decoration: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 10px 10px 6px !important; /* Different bottom to adjust for DejaVu Sans font position */
  overflow: hidden;
  background-color: transparent;
}

.the-MomentList-Card a:visited {
  color: #8a8a8a; /* A bit lighter than 'gray' */
}

@media screen and (max-width: 960px) {
  .the-MomentList-Card a {
    /* Stars background adapted from https://codepen.io/agoodwin/pen/NMJoER and https://multiple.js.org/ */
    background-color: black;
    background-image: url("../stars.png");
    background-size: 100vw 100vh;
    background-attachment: fixed;
    /* Use these to enable twinkling (but it's performance-intensive) */
    /* background-image: url("../twinkling.png"), url("../stars.png"); */
    /* background-size: cover 200%; */
    /* background-position: 0 0, 0 0; */
    /* animation: twinkle 5s linear infinite; */
  }
}

@media screen and (max-width: 340px) {
  .the-MomentList-Container {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
