.the-Moment-Container {
  text-align: center;
}

.the-Moment-Title {
  margin: 25px 15px 0 !important;
  text-shadow: var(--paper-shadow);
}

.the-Moment-Description, .the-Moment-Caption {
  font-size: 18px !important;
}

.the-Moment-Description {
  margin: 8px 8px 20px !important;
}

.the-Moment-Caption {
  margin: 8px !important;
}

.the-Moment-Image, .the-Moment-VideoContainer {
  margin: 10px auto 15px !important;
  max-width: 100%;
  display: block;
  box-shadow: var(--paper-shadow);
}

.the-Moment-Image {
  cursor: pointer;
}

.the-Moment-VideoContainer {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* For 16x9 aspect ratio */
}

.the-Moment-Video {
  border: none;

  /* Fill the container */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.the-Moment-Container a {
  color: white;
}

.the-Moment-Container a:visited {
  color: #8a8a8a; /* A bit lighter than 'gray' */
}

